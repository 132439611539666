<template>
  <div>
    <div class="df-c p-relative card-header tourism-category__place" style="background-image: url('/assets/img/berlin2.png')">
      <h3 class="tourism-category__place-name fs23">Cairo</h3>
      <CategoryShare></CategoryShare>
      <CategoryStar></CategoryStar>
    </div>
    <div class="card-body bg-white p-b-5">
      <slot name="title"></slot>
      <FlightDesData class="m-t-10">
        <template slot="additionalData">
          <slot name="actionPart"></slot>
        </template>
      </FlightDesData>
      <div class="d-flex flex-column align-items-center">
        <div class="m-t-20 fs14 text-center">
          {{ $t( "TOURISM.STARTING_FROM" ) }}: <b class="fs16"> 300₪</b>
        </div>
        <slot name="routerBlock"></slot>
        <p class="fs11 m-t-5">
          * {{ $t( "TOURISM.PRICE_SPECIFIC_FLIGHT_SHOWN" ) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RateStar from '../../RateStar';
import CategoryShare from '../../CategoryShare';
import CategoryStar from '../CategoryStar';
import FlightDesData from '../FlightDesData';

export default {
  name: "FlightTourismItem",
  props: {
    // isToAll: {
    //   type: Boolean,
    //   default: true
    // }
  },
  components: {
    RateStar,
    CategoryShare,
    CategoryStar,
    FlightDesData,
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .card-header {
    height: 180px;
    background-size: cover;
  }
  .fs16 {
    font-size: 16px !important;
  }
</style>