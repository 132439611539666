<template>
  <main
    class="tourism color-primary h-100 blank-aside-js"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  > 
    <BannerSection location="homepage-top"></BannerSection>
    <div class="d-flex justify-content-between align-items-center mx10">
      <h4 class="m-t-15">All flights</h4>
      <div class="d-flex m-t-15 mobile-filter-buttons">
        <button 
          class="primary-btn d-flex align-items-center mx5"
          @click="openFilterModal"
        >
          {{ $t('REPORT_SALES.SEARCH') }}
          <img src="/assets/img/icons/search-light-white.svg" alt="" class="seach-btn-img">
        </button>
        <SortTourism @sortDeals="sortDeals" :isTourismDeal="true"></SortTourism>
        <FilterTourism></FilterTourism>
      </div>
    </div>
    <MobileFilterModal :isFilterModalVisible.sync="isFilterModalVisible"></MobileFilterModal>
    <FlightTourismItem class="p10">
      <p class="fs14 text-center" slot="title">
        Flights to <b>Paris, France</b>
      </p>
      <button 
        class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
        slot="actionPart" 
        @click="openMobileDetailModal()"
      >
        <img src="/assets/img/icons/info.svg" alt="" class="">
        {{ $t( "TOURISM.FULL_DETAILS" ) }}
      </button>
      <router-link
        :to="{
          name: 'MobileFlightDestinationDates',
        }"
        slot="routerBlock" 
      >
        <button class="primary-btn small-btn fs16 bg-3">
          {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
        </button>
      </router-link>
    </FlightTourismItem>
    <FlightTourismItem class="p10">
      <p class="fs14 text-center" slot="title">
        Flights to <b>Paris, France</b>
      </p>
      <button 
        class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn m-t-15" 
        slot="actionPart" 
        @click="openMobileDetailModal()"
      >
        <img src="/assets/img/icons/info.svg" alt="" class="">
        {{ $t( "TOURISM.FULL_DETAILS" ) }}
      </button>
      <router-link
        :to="{
          name: 'MobileFlightDestinationDates',
        }"
        slot="routerBlock" 
      >
        <button class="primary-btn small-btn fs16 bg-3">
          {{ $t( "TOURISM.EXPLORE_MORE_DATES" ) }}
        </button>
      </router-link>
    </FlightTourismItem>
    <div class="df-c m-t-10">
      <button class="primary-btn primary-btn_sm">
        {{ $t( "TOURISM.SHOW_MORE" ) }}
      </button>
    </div>
    <MobileDetailModal :isOrder="false"></MobileDetailModal>
  </main>
</template>

<script>
import BannerSection from "../../../../components/BannerSection";
import TourismFilter from "../../../../components/tourism/TourismFilter.vue";
import FlightTourismItem from "../../../../components/tourism/flight/FlightTourismItem.vue";
import MobileDetailModal from "../../../../components/tourism/flight/MobileDetailModal.vue";
import MobileFilterModal from "../../../../components/tourism/flight/MobileFilterModal.vue";
import SortTourism from "../../../../components/tourism/SortTourism";
import FilterTourism from "../../../../components/tourism/FilterTourism";

export default {
  name: "MobileFligntHome",
  components: {
    BannerSection,
    TourismFilter,
    FlightTourismItem,
    MobileDetailModal,
    MobileFilterModal,
    SortTourism,
    FilterTourism
  },
  data() {
    return {
      deals: [{},{},{},{},{},{},{},{},{},{},{},{}],
      isFilterModalVisible: false
    }
  },
  methods: {
    openMobileDetailModal() {
      this.$modal.show("MobileDetailModal");
    },
    openFilterModal() {
      $("html").css("overflow", "hidden");
      this.isFilterModalVisible = true;
    },
    closeFilterModal() {
      $("html").css("overflow", "auto");
      this.isFilterModalVisible = false;
    },
    sortDeals() {

    }
  }
}
</script>

<style lang="scss">
@import "../../../../assets/scss/tourism.scss";
.mobile-filter-buttons {
  .toursim-sort-filter_btn {
    border-radius: 5px;
  }
  .toursim-sort-filter_btn {
    border-radius: 5px;
  }
}
</style>
